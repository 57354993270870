import React, { useState } from 'react'
import "./Menu.css"
import MenuElement from './MenuElement/MenuElement'


const Menu = (props) =>{
    const [selected , setSelected] = useState()

    return(
        <div className="Menu">
            {
                props.categories.map((categorie , index)=>{
                    if(selected === categorie.id){
                        return <MenuElement key={index} className="MenuElement selected">{categorie.Titre}</MenuElement>
                    }else{
                        return <MenuElement key={index} className="MenuElement" onClick={()=>{
                            props.newSelection(categorie.identifiant)
                            setSelected(categorie.id)
                        }}>{categorie.Titre}</MenuElement>
                    }
                })
            }
        </div>
    )
}

export default Menu