import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import './fonts.css';
import './colors.css';
import './Print.css';
import MainFrame from './components/MainFrame/MainFrame';
import Sidebar from './components/Sidebar/Sidebar';
import Translate from './components/Translate/Translate';

function App() {
  const [init , setInit] = useState(false)
  const [lang , setLang] = useState('FR')
  const [langs , setLangs] = useState(['FR','EN'])
  const [cartes , setCartes] = useState([])
  const [selectedCarte , setSelectedCarte] = useState([])
  const [categories , setCategories] = useState([])
  const [selectedCategorie , setSelectedCategorie] = useState([])

  const handleSelectedCategorie = async (identifiant)=>{
    if (identifiant === ""){
      setSelectedCategorie(categories[0])
    }else{
      setSelectedCategorie(()=>{
        return categories[categories.findIndex(cat => cat.identifiant === identifiant)]
      })
    }
    // setInit(false)
  }

  const flagClick = (langCode)=>{
    setLang(langCode)
    // setSheets([])
    setSelectedCategorie(categories[0])
    // setSelectedTitle("")
  }

  const baseURL = 'https://gestion.le-balcon-70.fr'

  
    const getCategories = async ()=>{
      await axios(`${baseURL}/carte-categories?_locale=${lang}`, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response)=> {
          
          setSelectedCategorie(response.data[0])
          setCategories(response.data)
        })
        .catch((error)=> {
          console.log(error);
        });
    }

  const getCartes = async ()=>{
    await axios(`${baseURL}/cartes-pages?_locale=${lang}`, {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => {
      setCartes(response.data)
    }).catch((error)=> {
      console.log(error);
    });
  }

  const getSelectedCartes = ()=>{
    let selected = []
    cartes.map(carte=>{
      if(carte.Categories === selectedCategorie.identifiant){
        selected.push(carte)
      }
    })
    return selected
  }

  useEffect(() => {
    if(!init){
      window.scrollTo({top: 0, behavior: 'smooth'})
      getCategories()
      .then(getCartes())
      .then(setInit(true))
    }
  });
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
    const select = getSelectedCartes()
    setSelectedCarte(select)
  },[selectedCategorie,cartes]);
  
  return (
    <div className="App">
      <Sidebar categories = {categories} newSelection={ (identifiant)=>{ handleSelectedCategorie(identifiant) }}></Sidebar>
      <div className="InnerWindow">
        <Translate lang={lang} langs={langs} newLang={(langCode)=>{flagClick(langCode); setInit(false)}}></Translate>
        <MainFrame selectedCategorie = {selectedCategorie} cards={selectedCarte}></MainFrame>
      </div>
    </div>
  );
}

export default App;
