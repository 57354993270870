import React from 'react'
import './MainFrame.css';
import MainTitle from './MainTitle/MainTitle';
import CategoryBlock from './CategoryBlock/CategoryBlock';


const MainFrame = ({selectedCategorie , cards}) =>{
    console.log(cards[0])

    if(selectedCategorie.Titre === "La Carte"){
        return(
            <div className="MainFrame">
                <MainTitle>{selectedCategorie?.Titre}</MainTitle>
                <div id="contentBlock">
                    <div className='laCarteSubContainer'>
                        <div key={0} className={'contentPageContainer '+selectedCategorie.identifiant} >
                            <div className={'contentPage '+selectedCategorie.identifiant} dangerouslySetInnerHTML={{__html: `${cards[0]?.Page_contenu}`}}></div>
                        </div>
                        <div key={1} className={'contentPageContainer '+selectedCategorie.identifiant} >
                            <div className={'contentPage '+selectedCategorie.identifiant} dangerouslySetInnerHTML={{__html: `${cards[1]?.Page_contenu}`}}></div>
                        </div>
                    </div>
                    <div className='laCarteSubContainer'>
                        <div key={2} className={'contentPageContainer '+selectedCategorie.identifiant} >
                            <div className={'contentPage '+selectedCategorie.identifiant} dangerouslySetInnerHTML={{__html: `${cards[2]?.Page_contenu}`}}></div>
                        </div>
                        <div key={3} className={'contentPageContainer '+selectedCategorie.identifiant} >
                            <div className={'contentPage '+selectedCategorie.identifiant} dangerouslySetInnerHTML={{__html: `${cards[3]?.Page_contenu}`}}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="MainFrame">
                <MainTitle>{selectedCategorie?.Titre}</MainTitle>
                <div id="contentBlock">
                    {cards.map((card , index)=>
                        <div key={index} className={'contentPageContainer '+selectedCategorie.identifiant} >
                            <div className={'contentPage '+selectedCategorie.identifiant} dangerouslySetInnerHTML={{__html: `${card.Page_contenu}`}}></div>
                        </div>
                    )}
                </div>
        </div>
    )
}

export default MainFrame