import React from 'react'
import './Translate.css'
import Flag from './Flag/Flag'

const Translate = (props) => {

    const printNewLang = (langCode)=>{
        props.newLang(langCode)
    }

    return(
        <div className="Translate"> 
            {
                props.langs.map((data , index)=>{
                    if(props.lang === data){
                        return <Flag key={index} lang={data} newLang={()=>{printNewLang(data)}} selected>{data.title}</Flag>
                    }else{
                        return <Flag key={index} lang={data} newLang={()=>{printNewLang(data)}}>{data.title}</Flag>
                    }
                })
            }
        </div>
    )
}

export default Translate