import React from 'react'
import './Flag.css'

const Flag = (props) => {
    const click = ()=>{
        props.newLang()
    }
    if(props.selected){
        return <div className={"Flag selected "+props.lang} onClick={click}></div>
    }
    return <div className={"Flag "+props.lang} onClick={click}></div>
}

export default Flag