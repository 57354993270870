import React from 'react'
import './Logo.css';


const Logo = (props) =>{
    return(
        <div className="Logo"></div>
    )
}

export default Logo