import React from 'react'
import './MainTitle.css';

const MainTitle = (props) =>{
    return (
        <div id="mainTitle">
            <h1 className="title">{props.children}</h1>
        </div>
    )
}

export default MainTitle