import React, { useState } from 'react'
import './Sidebar.css';
import Logo from '../Logo/Logo'
import Menu from './Menu/Menu'

const scrollToTop = ()=>{
    const contentBlock = document.getElementById('contentBlock')
    contentBlock.scrollTop = 0
}

const Sidebar = (props) =>{
    const [burgerState , setBurgerState] = useState("close")

    const newSelection = (identifiant)=>{
        scrollToTop()
        props.newSelection(identifiant)
        setBurgerState("close")
    }
    const burgerClick = ()=>{
        if(burgerState === "open"){
            setBurgerState("close")
        }else{
            setBurgerState("open")
        }
    }

    return(
        <div id="SideBar" className={burgerState}>
            <Logo></Logo>
            <div id="burger-box">
                <div id="burger" className={burgerState} onClick={()=>{burgerClick()}}><span></span><span></span><span></span></div>
            </div>
            <Menu categories={props.categories} newSelection={  (identifiant)=>{ newSelection(identifiant) }  }></Menu>
        </div>
    )
}

export default Sidebar