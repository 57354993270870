import React from 'react'
import './MenuElement.css'

const MenuElement = (props) => {
    return(
        <div className={props.className} onClick={props.onClick}>
            <p>
                {props.children}
            </p>
        </div>
    )
}

export default MenuElement